import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import {
  ssuRoles,
  CHANGE_SSU_ROLE_MODAL,
  marketplaceRoles
} from '../../constants';
import { showModal } from '../../actions/modals';
import UserRoleFormTemplate from './UserRoleFormTemplate';
import { saveNotAllowed, prepareAddRolePayload } from './UserRoleFormService';
import {
  getStudiesDataSource,
  getSitesDataSource,
  getPcnsDataSource
} from './SsuDropdownService';

const UserRoleForm = ({
  postAddRoleRequest,
  userId,
  ssus,
  ssuList,
  allStudiesDataSource,
  allSitesDataSource,
  allPcnsDataSource,
  pcnStudyMap,
  siteStudyMap,
  studies,
  sites,
  roles,
  showConfirmationModal,
  postCognitoUserAddGroupRequest
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        userId,
        roleStartDateEnable: true,
        roleExpireDateEnable: true,
        roleStartDate: '',
        roleExpireDate: '',
        roleTimeZone: '',
        roleTimeZoneAbbr: 'UTC',
        roleExpireTime: '23:59',
        roleStartTime: '00:00',
        googleTimeZoneName: '',
        role: '',
        selectedSiteId: '',
        selectedSiteIds: [],
        selectedStudyId: '',
        roleSsu: '',
        blindingGroup: '',
        financeAccess: [],
        groupAssignId: ''
      }}
      onSubmit={values => {
        if (marketplaceRoles.includes(values.role)) {
          postCognitoUserAddGroupRequest(values.userId, {
            role: values.role
          });
        } else {
          const payload = prepareAddRolePayload(values, ssus);
          if (
            ssuRoles.includes(values.role) &&
            values.roleSsu &&
            roles.some(
              role =>
                role.role === values.role &&
                role.roleSsu === values.roleSsu &&
                role.blindingGroup
            )
          ) {
            showConfirmationModal(CHANGE_SSU_ROLE_MODAL, {
              onSubmitDialog: () => {
                postAddRoleRequest(values.userId, payload);
              }
            });
          } else {
            postAddRoleRequest(values.userId, payload);
          }
        }
      }}
    >
      {({ values }) => {
        return (
          <Form>
            <UserRoleFormTemplate
              values={values}
              ssus={ssus}
              studiesDataSource={getStudiesDataSource(
                values.selectedSiteId,
                values.selectedStudyId,
                values.selectedPcn,
                ssus,
                ssuList,
                allStudiesDataSource,
                pcnStudyMap,
                siteStudyMap
              )}
              sitesDataSource={getSitesDataSource(
                values.selectedSiteId,
                values.selectedStudyId,
                values.selectedPcn,
                ssus,
                ssuList,
                allSitesDataSource,
                pcnStudyMap
              )}
              pcnsDataSource={getPcnsDataSource(
                values.selectedSiteId,
                values.selectedStudyId,
                ssus,
                ssuList,
                allPcnsDataSource,
                studies
              )}
              sites={sites}
              studies={studies}
            />
            <div style={{ width: '100%', textAlign: 'right' }}>
              <button type="submit" disabled={saveNotAllowed(values, ssus)}>
                Add Role
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

UserRoleForm.defaultProps = {
  userId: null,
  ssus: {},
  studies: [],
  sites: [],
  ssuList: [],
  allStudiesDataSource: [],
  allSitesDataSource: [],
  allPcnsDataSource: [],
  pcnStudyMap: {},
  siteStudyMap: {},
  roles: {}
};

UserRoleForm.propTypes = {
  userId: PropTypes.string,
  ssus: PropTypes.object,
  ssuList: PropTypes.array,
  allStudiesDataSource: PropTypes.array,
  allSitesDataSource: PropTypes.array,
  allPcnsDataSource: PropTypes.array,
  pcnStudyMap: PropTypes.object,
  siteStudyMap: PropTypes.object,
  studies: PropTypes.array,
  sites: PropTypes.array,
  roles: PropTypes.object,
  postAddRoleRequest: PropTypes.func.isRequired,
  showConfirmationModal: PropTypes.func.isRequired,
  postCognitoUserAddGroupRequest: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  showConfirmationModal: (modalType, data) =>
    dispatch(showModal(modalType, data))
});

export default connect(
  null,
  mapDispatchToProps
)(UserRoleForm);
