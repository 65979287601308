/*
  '/users/add/:uuid' Page
*/
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import StandardTable from './gizmos/StandardTable';
import withUserAdd from '../containers/withUserAdd';
import UserRoleFormTemplate from './User/UserRoleFormTemplate';
import {
  saveNotAllowed,
  prepareAddUserPayload
} from './User/UserRoleFormService';
import {
  getStudiesDataSource,
  getSitesDataSource,
  getPcnsDataSource
} from './User/SsuDropdownService';
import { titleOptions } from '../constants';
import bouncerToken from '../fetchers/bouncerToken';
import Auth0Info from './User/Auth0Info';
import {
  leftColumnStyle,
  readOnlyStyle,
  rightColumnStyle
} from './commonStyles';

const formStyle = {
  display: 'grid',
  gridTemplateColumns: '10rem auto',
  gridRowGap: '1.25rem',
  marginBottom: '2rem'
};

const UserInfo = ({ addableUser }) => (
  <section style={formStyle}>
    <label htmlFor="userId" style={leftColumnStyle}>
      User ID:
    </label>
    <Field
      id="userId"
      name="displayId"
      type="text"
      style={readOnlyStyle}
      readOnly
    />
    <Auth0Info auth0User={addableUser} />

    <label htmlFor="lastName" style={leftColumnStyle}>
      Last Name
    </label>
    <Field
      id="lastName"
      name="lastName"
      type="text"
      style={rightColumnStyle}
      className="req-field"
    />

    <label htmlFor="firstName" style={leftColumnStyle}>
      First Name
    </label>
    <Field
      id="firstName"
      name="firstName"
      type="text"
      style={rightColumnStyle}
      className="req-field"
    />

    <label htmlFor="email" style={leftColumnStyle}>
      Email
    </label>
    <Field
      id="email"
      name="email"
      type="email"
      style={readOnlyStyle}
      readOnly
    />

    <label htmlFor="title" style={leftColumnStyle}>
      Title
    </label>
    <Field
      name="title"
      component="select"
      style={{ ...rightColumnStyle, height: '28px' }}
    >
      <option disabled selected />
      {titleOptions.map(option => (
        <option value={option}>{option}</option>
      ))}
    </Field>
    <label htmlFor="department" style={leftColumnStyle}>
      Department
    </label>
    <Field
      id="department"
      name="department"
      type="text"
      style={rightColumnStyle}
    />

    <label htmlFor="startDate" style={leftColumnStyle}>
      Start Date
    </label>
    <Field
      id="startDate"
      name="startDate"
      type="date"
      style={rightColumnStyle}
    />

    <label htmlFor="jobTitle" style={leftColumnStyle}>
      Job Title
    </label>
    <Field id="jobTitle" name="jobTitle" type="text" style={rightColumnStyle} />

    <label htmlFor="credentials" style={leftColumnStyle}>
      Credentials
    </label>
    <Field
      id="credentials"
      name="credentials"
      type="text"
      style={rightColumnStyle}
    />

    <label htmlFor="notes" style={leftColumnStyle}>
      Notes
    </label>
    <Field id="notes" name="notes" type="text" style={rightColumnStyle} />
  </section>
);

UserInfo.defaultProps = {
  addableUser: {}
};

UserInfo.propTypes = {
  addableUser: PropTypes.object
};

const UserAddForm = ({
  addableUser,
  ssus,
  ssuList,
  allStudiesDataSource,
  allSitesDataSource,
  allPcnsDataSource,
  pcnStudyMap,
  siteStudyMap,
  studies,
  sites,
  history,
  postUserRequest
}) => {
  // eslint-disable-next-line camelcase
  const { username, family_name, given_name, email } = addableUser;

  let displayId = username;
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  if (bouncerEnabled) {
    const cognitoId = get(
      addableUser,
      'user_metadata.originalCognito.username'
    );
    if (cognitoId) {
      displayId = cognitoId;
    } else {
      displayId = null;
    }
  }
  // YYYY-MM-DD
  function formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    return [year, month, day].join('-');
  }
  const todayString = formatDate(new Date());

  return (
    <Formik
      enableReinitialize
      initialValues={{
        userId: username,
        displayId,
        lastName: family_name,
        firstName: given_name,
        email,
        title: '',
        department: '',
        startDate: todayString,
        roleStartDateEnable: true,
        roleExpireDateEnable: true,
        roleTimeZone: '',
        roleTimeZoneAbbr: '',
        googleTimeZoneName: '',
        roleStartTime: '00:00',
        roleExpireTime: '23:59',
        roleStartDate: '',
        roleExpireDate: '',
        role: '',
        selectedSiteId: '',
        selectedSiteIds: [],
        selectedStudyId: '',
        blindingGroup: '',
        financeAccess: [],
        jobTitle: '',
        credentials: '',
        notes: '',
        groupAssignId: ''
      }}
      onSubmit={values => {
        const { userId, lastName, firstName } = values;
        const payload = prepareAddUserPayload(values, ssus);

        const missingRequiredFields = [];
        if (!lastName || !lastName.trim()) {
          missingRequiredFields.push('Last Name');
        }
        if (!firstName || !firstName.trim()) {
          missingRequiredFields.push('First Name');
        }

        if (missingRequiredFields.length !== 0) {
          toast.error(
            `Missing required fields: ${missingRequiredFields.join(', ')}`
          );
        } else {
          postUserRequest(userId, payload, history);
        }
      }}
    >
      {({ values }) => (
        <Form>
          <UserInfo addableUser={addableUser} />
          <UserRoleFormTemplate
            values={values}
            ssus={ssus}
            studiesDataSource={getStudiesDataSource(
              values.selectedSiteId,
              values.selectedStudyId,
              values.selectedPcn,
              ssus,
              ssuList,
              allStudiesDataSource,
              pcnStudyMap,
              siteStudyMap
            )}
            sitesDataSource={getSitesDataSource(
              values.selectedSiteId,
              values.selectedStudyId,
              values.selectedPcn,
              ssus,
              ssuList,
              allSitesDataSource,
              pcnStudyMap
            )}
            pcnsDataSource={getPcnsDataSource(
              values.selectedSiteId,
              values.selectedStudyId,
              ssus,
              ssuList,
              allPcnsDataSource,
              studies
            )}
            sites={sites}
            studies={studies}
          />
          <section style={{ marginTop: '10px' }}>
            <button
              type="submit"
              disabled={saveNotAllowed(values, ssus)}
              style={{ marginRight: '10px' }}
            >
              Submit
            </button>
            <button type="reset">Reset Form</button>
          </section>
        </Form>
      )}
    </Formik>
  );
};

UserAddForm.defaultProps = {
  ssus: {},
  studies: [],
  sites: [],
  ssuList: [],
  allStudiesDataSource: [],
  allSitesDataSource: [],
  allPcnsDataSource: [],
  pcnStudyMap: {},
  siteStudyMap: {}
};

UserAddForm.propTypes = {
  addableUser: PropTypes.shape({
    username: PropTypes.string,
    family_name: PropTypes.string,
    given_name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  ssus: PropTypes.object,
  ssuList: PropTypes.array,
  allStudiesDataSource: PropTypes.array,
  allSitesDataSource: PropTypes.array,
  allPcnsDataSource: PropTypes.array,
  pcnStudyMap: PropTypes.object,
  siteStudyMap: PropTypes.object,
  studies: PropTypes.array,
  sites: PropTypes.array,
  history: PropTypes.object.isRequired,
  postUserRequest: PropTypes.func.isRequired
};

class UserAdd extends Component {
  constructor(props) {
    super(props);
    const { bouncerEnabled } = bouncerToken.getBouncerProps();
    this.cognitoUserColumns = [
      {
        Header: 'Family Name',
        accessor: 'family_name'
      },
      {
        Header: 'Given Name',
        accessor: 'given_name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Status',
        accessor: 'status',
        show: !bouncerEnabled
      },
      {
        Header: 'Cognito Groups',
        accessor: 'groups',
        Cell: ({ value }) => (value && value.join(', ')) || null,
        show: !bouncerEnabled
      },
      {
        Header: 'Email Verified',
        accessor: 'email_verified'
      },
      {
        Header: bouncerEnabled ? 'ProviderId' : 'UserName',
        accessor: 'username'
      }
    ];
  }

  componentDidMount() {
    const {
      fetchAddableUserRequest,
      fetchSsusRequest,
      fetchStudiesRequest,
      fetchSitesRequest,
      match
    } = this.props;
    fetchAddableUserRequest(match.params.uuid);
    fetchSsusRequest();
    fetchStudiesRequest();
    fetchSitesRequest();
  }

  render() {
    const {
      addableUser,
      ssus,
      ssuList,
      allStudiesDataSource,
      allSitesDataSource,
      allPcnsDataSource,
      pcnStudyMap,
      siteStudyMap,
      studies,
      sites,
      history,
      postUserRequest
    } = this.props;
    return (
      <Fragment>
        <h1>Create User</h1>
        <StandardTable
          columns={this.cognitoUserColumns}
          data={[addableUser]}
          pageSize={1}
          showPagination={false}
          style={{ marginBottom: '2rem' }}
        />
        <UserAddForm
          addableUser={addableUser}
          ssus={ssus}
          ssuList={ssuList}
          allStudiesDataSource={allStudiesDataSource}
          allSitesDataSource={allSitesDataSource}
          allPcnsDataSource={allPcnsDataSource}
          pcnStudyMap={pcnStudyMap}
          siteStudyMap={siteStudyMap}
          studies={studies}
          sites={sites}
          history={history}
          postUserRequest={postUserRequest}
        />
      </Fragment>
    );
  }
}

UserAdd.propTypes = {
  addableUser: PropTypes.object.isRequired,
  ssus: PropTypes.array.isRequired,
  ssuList: PropTypes.array.isRequired,
  allStudiesDataSource: PropTypes.array.isRequired,
  allSitesDataSource: PropTypes.array.isRequired,
  allPcnsDataSource: PropTypes.array.isRequired,
  pcnStudyMap: PropTypes.object.isRequired,
  siteStudyMap: PropTypes.object.isRequired,
  studies: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: {
      uuid: PropTypes.string
    }
  }).isRequired,
  fetchAddableUserRequest: PropTypes.func.isRequired,
  fetchSsusRequest: PropTypes.func.isRequired,
  fetchStudiesRequest: PropTypes.func.isRequired,
  fetchSitesRequest: PropTypes.func.isRequired,
  postUserRequest: PropTypes.func.isRequired
};

export default withUserAdd(UserAdd);
