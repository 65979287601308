import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Formik, Form } from 'formik';
import StandardTable from '../gizmos/StandardTable';
import commonColumns from './CommonColumns';
import { timeZones } from '../../constants';
import ExpireDateForm from './ExpireDateForm';

const ReactivateFormSingle = (
  postAddRoleRequest,
  {
    roleName,
    ssuId,
    blindingGroup,
    groupAssign,
    financeDetailsAccess,
    patientPaymentAccess,
    remittanceNotification,
    timeZoneObj = {}
  },
  userId,
  today,
  timeZoneDataSource
) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        roleName,
        ssuId,
        expireDate: today,
        userId,
        blindingGroup,
        groupAssign,
        financeDetailsAccess,
        patientPaymentAccess,
        remittanceNotification,
        showDateTimeFields: false,
        expireTime: '23:59',
        timeZone: timeZoneObj.id || '',
        timeZoneAbbr: timeZoneObj.abbr || '',
        googleTimeZoneName: timeZoneObj.googleName || ''
      }}
      onSubmit={values => {
        const payload = {
          ssu: values.ssuId,
          role: values.roleName,
          'role-expire-date':
            (values.showDateTimeFields && values.expireDate) || null,
          'role-expire-time':
            (values.showDateTimeFields && values.expireTime) || null,
          'role-time-zone':
            (values.showDateTimeFields && values.timeZone) || null,
          'role-time-zone-abbr':
            (values.showDateTimeFields && values.timeZoneAbbr) || null,
          'google-time-zone-name':
            (values.showDateTimeFields && values.googleTimeZoneName) || null,
          'role-expire-date-enable': true,
          'role-ssu': values.ssuId,
          'role-study-blinding-group': values.blindingGroup,
          'role-study-group-assign-id':
            (values.groupAssign && values.groupAssign._id) || null,
          'role-finance-access': {
            financeDetailsAccess,
            patientPaymentAccess,
            remittanceNotification
          }
        };

        postAddRoleRequest(values.userId, payload);
      }}
    >
      {({ values, errors, touched }) => (
        <ExpireDateForm
          values={values}
          errors={errors}
          touched={touched}
          showDateTimeText=" Set an Expire Date"
          timeZoneObj={timeZoneObj}
          timeZoneDataSource={timeZoneDataSource}
          submitText="Reactivate Now"
          showDateTimeSubmitText="Reactivate Now"
        />
      )}
    </Formik>
  );
};

const ReactivateSMGroup = ({
  postCognitoUserAddGroupRequest,
  userId,
  role
}) => (
  <Formik
    initialValues={{}}
    onSubmit={() => {
      postCognitoUserAddGroupRequest(userId, { role });
    }}
  >
    <Form>
      <button type="submit">Reactivate Now</button>
    </Form>
  </Formik>
);

ReactivateSMGroup.defaultProps = {
  userId: null,
  role: ''
};

ReactivateSMGroup.propTypes = {
  userId: PropTypes.string,
  role: PropTypes.string,
  postCognitoUserAddGroupRequest: PropTypes.func.isRequired
};

const ExpiredRolesTable = ({
  postAddRoleRequest,
  postCognitoUserAddGroupRequest,
  userId,
  previousRoles
}) => {
  const today = moment().format('YYYY-MM-DD');
  const timeZoneDataSource = timeZones.map(zone => ({
    ...zone,
    size: 'Medium'
  }));

  const columns = [
    {
      Header: 'App',
      accessor: 'app',
      minWidth: 150
    },
    ...commonColumns,
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ original }) =>
        (original.app === 'Directsource' &&
          ReactivateFormSingle(
            postAddRoleRequest,
            original,
            userId,
            today,
            timeZoneDataSource
          )) || (
          <ReactivateSMGroup
            postCognitoUserAddGroupRequest={postCognitoUserAddGroupRequest}
            userId={userId}
            role={original.roleName}
          />
        ),
      minWidth: 400
    }
  ];

  return (
    <StandardTable
      columns={columns}
      data={previousRoles}
      showPagination={false}
      pageSize={previousRoles.length}
    />
  );
};

ExpiredRolesTable.defaultProps = {
  userId: null,
  previousRoles: []
};

ExpiredRolesTable.propTypes = {
  userId: PropTypes.string,
  previousRoles: PropTypes.array,
  postAddRoleRequest: PropTypes.func.isRequired,
  postCognitoUserAddGroupRequest: PropTypes.func.isRequired
};

export default ExpiredRolesTable;
