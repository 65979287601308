import React from 'react';
import { financeAccessRoles, roleNames } from '../../constants';
import financeAccessOptions from './UserRoleFormTemplateConstants';

const commonColumns = [
  {
    Header: 'Role',
    accessor: 'roleName',
    Cell: ({ value }) => (value && roleNames[value]) || '',
    minWidth: 150
  },
  {
    Header: 'Study',
    accessor: 'studyName',
    minWidth: 150
  },
  {
    Header: 'Site',
    accessor: 'siteName',
    minWidth: 120
  },
  {
    Header: 'Start Date',
    accessor: 'momentStartDate', // sort by this
    Cell: ({ original }) => original.startDate, // but display this
    width: 190
  },
  {
    Header: 'Expire Date',
    accessor: 'momentExpireDate', // sort by this
    Cell: ({ original }) => original.expireDate, // but display this
    width: 190
  },
  {
    Header: 'Group',
    /* eslint-disable react/prop-types */
    Cell: ({ original }) => {
      const groupAssignName =
        original && original.groupAssign ? original.groupAssign.name : null;
      const blindingGroup = original.blindingGroup
        ? original.blindingGroup
        : null;
      return (
        <div style={{ whiteSpace: 'break-spaces' }}>
          {groupAssignName || blindingGroup || ''}
        </div>
      );
    },
    /* eslint-enable react/prop-types */
    width: 125
  },
  {
    Header: 'Finance Access',
    accessor: 'financeAccess',
    Cell: ({ original }) => {
      if (financeAccessRoles.includes(original.roleName)) {
        return financeAccessOptions[original.roleName]
          .filter(
            option =>
              original[option.id] === 'true' || original[option.id] === true
          )
          .map(option => option.name)
          .join(', ');
      }
      return '';
    },
    width: 200
  }
];
export default commonColumns;
