import { isEmpty } from 'lodash';
import { financeAccessRoles, ssuRoles } from '../../constants';
import financeAccessOptions from './UserRoleFormTemplateConstants';

export function ssuFilter(ssus) {
  return Object.keys(ssus).filter(s => ssus[s].status !== 'Canceled');
}

export function findSsu(ssuFiltered, selectedSiteId, selectedStudyId) {
  return ssuFilter(ssuFiltered).find(
    id =>
      ssuFiltered[id].studyId === selectedStudyId &&
      ssuFiltered[id].siteId === selectedSiteId
  );
}

function findSsus(ssuFiltered, selectedSiteIds, selectedStudyId) {
  return ssuFilter(ssuFiltered).filter(
    id =>
      ssuFiltered[id].studyId === selectedStudyId &&
      selectedSiteIds.includes(ssuFiltered[id].siteId)
  );
}

const getFinanceAccess = ({ financeAccess = [] }, role) => {
  const result = {};
  financeAccess.forEach(access => {
    result[access.id] = true;
  });

  financeAccessOptions[role].forEach(option => {
    if (!result[option.id]) {
      result[option.id] = false;
    }
  });
  return result;
};

export function saveNotAllowed(values, ssus) {
  const { role, selectedSiteId, selectedStudyId, blindingGroup } = values;
  const isSsuRole = ssuRoles.includes(role);
  const roleSsu = findSsu(ssus, selectedSiteId, selectedStudyId);
  const ssuIsSelected = selectedSiteId && selectedStudyId;
  return (
    (isSsuRole && !ssuIsSelected) ||
    (isSsuRole &&
      ssuIsSelected &&
      ssus[roleSsu].studyBlinded &&
      !ssus[roleSsu].blindingGroups.includes(blindingGroup)) ||
    (isSsuRole &&
      ssuIsSelected &&
      ssus[roleSsu].isGroupAssign &&
      values.groupAssignId === '')
  );
}

export function prepareAddRolePayload(values, ssus) {
  const { role, selectedStudyId, selectedSiteId, selectedSiteIds } = values;

  const payload = {
    role: values.role,
    selectedSiteId,
    selectedStudyId,
    selectedPcn: values.selectedPcn,
    'role-start-date-enable': values.roleStartDateEnable,
    'role-time-zone': values.roleTimeZone,
    'role-time-zone-abbr': values.roleTimeZoneAbbr,
    'google-time-zone-name': values.googleTimeZoneName,
    'role-start-date':
      values.roleStartDate === '' ? null : values.roleStartDate,
    'role-start-time': values.roleStartTime,
    'role-expire-date-enable': values.roleExpireDateEnable,
    'role-expire-date':
      values.roleExpireDate === '' ? null : values.roleExpireDate,
    'role-expire-time': values.roleExpireTime,
    'role-study-group-assign-id':
      values.groupAssignId === '' ? null : values.groupAssignId
  };

  if (financeAccessRoles.includes(role)) {
    Object.assign(payload, {
      'role-finance-access': getFinanceAccess(values, role)
    });
  }
  if (role === 'sitefincoord' && !isEmpty(selectedSiteIds)) {
    Object.assign(payload, {
      'role-ssus': findSsus(ssus, selectedSiteIds, selectedStudyId)
    });
  } else {
    Object.assign(payload, {
      'role-ssu': findSsu(ssus, selectedSiteId, selectedStudyId)
    });
  }

  return payload;
}

export function prepareAddUserPayload(values, ssus) {
  const {
    userId,
    lastName,
    firstName,
    email,
    title,
    department,
    startDate,
    roleStartDateEnable,
    roleTimeZone,
    roleTimeZoneAbbr,
    roleStartDate,
    roleStartTime,
    roleExpireDateEnable,
    googleTimeZoneName,
    roleExpireDate,
    roleExpireTime,
    role,
    jobTitle,
    credentials,
    notes,
    groupAssignId,
    selectedStudyId,
    selectedSiteId,
    selectedSiteIds
  } = values;

  const payload = {
    userId,
    lastName,
    firstName,
    email,
    title,
    department,
    startDate,
    'role-time-zone': roleTimeZone,
    'role-time-zone-abbr': roleTimeZoneAbbr,
    'role-start-date-enable': roleStartDateEnable,
    'role-start-date': roleStartDate,
    'role-start-time': roleStartTime,
    'role-expire-time': roleExpireTime,
    'google-time-zone-name': googleTimeZoneName,
    'role-expire-date-enable': roleExpireDateEnable,
    'role-expire-date': roleExpireDate,
    role,
    'role-study-group-assign-id': groupAssignId,
    jobTitle,
    credentials,
    notes
  };

  if (financeAccessRoles.includes(role)) {
    Object.assign(payload, {
      'role-finance-access': getFinanceAccess(values, role)
    });
  }
  if (role === 'sitefincoord' && !isEmpty(selectedSiteIds)) {
    Object.assign(payload, {
      'role-ssus': findSsus(ssus, selectedSiteIds, selectedStudyId)
    });
  } else {
    Object.assign(payload, {
      'role-ssu': findSsu(ssus, selectedSiteId, selectedStudyId)
    });
  }

  return payload;
}
