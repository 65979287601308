const financeAccessOptionsList = [
  {
    name: 'Finance Detail Access',
    id: 'financeDetailsAccess',
    initialValue: false
  },
  {
    name: 'Remittance Notification',
    id: 'remittanceNotification',
    initialValue: false,
    disabled: true
  }
];

const financeAccessOptions = {
  stuman: [
    {
      name: 'Patient Payment Access',
      id: 'patientPaymentAccess',
      initialValue: true
    },
    ...financeAccessOptionsList
  ],
  pi: financeAccessOptionsList,
  opman: financeAccessOptionsList,
  sitefincoord: financeAccessOptionsList
};
export default financeAccessOptions;
