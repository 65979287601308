import React, { useMemo } from 'react';
import { sortBy, find } from 'lodash';
import { ssuRoles } from '../../../constants';
import SingleSelect from '../../gizmos/SingleSelect';

/* eslint-disable react/prop-types */
export default function UserRoleSitesSelectField({
  field,
  form,
  sitesDataSource
}) {
  const { values } = form;

  const dataSource = useMemo(
    () => sortBy(sitesDataSource, elem => elem.name.toUpperCase()),
    [sitesDataSource]
  );

  const value = useMemo(
    () =>
      find(dataSource, elem => {
        return elem.id === field.value;
      }),
    [dataSource]
  );

  return (
    <SingleSelect
      useNewStyle
      label="Site"
      placeholder="Select a Site"
      initialValue={value}
      disabled={!ssuRoles.includes(values.role)}
      dataSource={dataSource}
      onChange={i => {
        form.setFieldValue('selectedSiteId', (i && i.id) || '');
        form.setFieldValue('selectedSiteIds', i ? [i.id] : []);
      }}
      searchable
      clearSearchOnSelection
    />
  );
}
/* eslint-enabled react/prop-types */
